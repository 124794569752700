import Axios from "axios";
import {getAccessToken} from "./sessionKey";
import { toast } from "react-toastify";

const API_ROOT ='https://usermanagement.dibsolutions.com.au/api/'

const apiInstance = Axios.create({
  baseURL: `${API_ROOT}`,
  headers: {
    "Content-type": "Application/json",
    // 'Content-Type': 'multipart/form-data'
    "Authorization": `JWT ${getAccessToken()}`,
  },
});

apiInstance.interceptors.request.use(
  function(config:any) {
    config.headers = {
      'x-requested-with': '',
      authorization: `JWT ${getAccessToken()}`,
    };
    return config;
  },
  function(error) {
    return Promise.reject(error);
  },
);

apiInstance.interceptors.response.use(
  function(response) {
    return response;
  },
  function(error) {
    const { response } = error;
    if (response) {
      if (response.status === 404) {
        toast.error('Error');
      } else if (response.status === 401) {
        console.log("status", response)
        window.location.replace("/auth-login")
      } else {
        toast.error(response.data.msg);
      }
    }

    return Promise.reject(error);
  },
);

export {apiInstance, API_ROOT};

