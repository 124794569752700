//@no-check
import { Menu, Transition } from '@headlessui/react'
import { Fragment, useState } from 'react'
import ServiceIcon from "../../assets/grid.svg"
import { getLocalKey } from '../../helpers/sessionKey'

export default function AvailableService() {
  const [services, setServices] = useState()
  function fetchService() {
    const response = fetch(
      "https://tenantcontrol.dibsolutions.com.au/api/services/",
      {
        headers: {
          "Content-type": "application/json; charset=UTF-8",
          "Authorization": `JWT ${getLocalKey("token")}`
        }
      }
    ).then(res => res.json)
      .then(data => {
        console.log(data)
      });
  }

  const handleRoute = (path: string) => {
    window.location.replace(path)
  }
  return (
    <Menu as="div" className="relative inline-block text-left">
      <div>
        <Menu.Button className="inline-flex w-full justify-center rounded-md my-2 text-sm font-medium text-white hover:bg-opacity-30 focus:outline-none focus-visible:ring-2 focus-visible:ring-white focus-visible:ring-opacity-75">
          <img src={ServiceIcon} alt="Service icons" />
        </Menu.Button>
      </div>
      <Transition
        as={Fragment}
        enter="transition ease-out duration-100"
        enterFrom="transform opacity-0 scale-95"
        enterTo="transform opacity-100 scale-100"
        leave="transition ease-in duration-75"
        leaveFrom="transform opacity-100 scale-100"
        leaveTo="transform opacity-0 scale-95"
      >
        <Menu.Items className="absolute right-0 mt-2 w-56 origin-top-right divide-y divide-gray-100 rounded-md bg-white shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none">
          <div className='w-full grid grid-cols-2'>
            <div onClick={() => handleRoute("/")}>
              <div className="bg-white flex flex-col items-center justify-center pt-8 pb-4 shadow-sm rounded-xl mx-2 gap-y-2">
                <img
                  src={
                    "https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQp7_6BOtmnzK4Qz1mf1JYNEhkgKPzymcgCRNUli0AqgFzgLlqJJyLQyrnRfLlJVeAckX4&usqp=CAU"
                  }
                  alt=""
                  className="rounded-full w-14 h-14 object-fill"
                />
                <h3 className="font-semibold capitalize">Dashboard</h3>
              </div>
            </div>
            <div onClick={() => handleRoute("/user-management")}>
              <div className="bg-white flex flex-col items-center justify-center pt-8 pb-4 shadow-sm rounded-xl mx-2 gap-y-2">
                <img
                  src={
                    "https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQp7_6BOtmnzK4Qz1mf1JYNEhkgKPzymcgCRNUli0AqgFzgLlqJJyLQyrnRfLlJVeAckX4&usqp=CAU"
                  }
                  alt=""
                  className="rounded-full w-14 h-14 object-fill"
                />
                <h3 className="font-semibold capitalize">I AM</h3>
              </div>
            </div>
            <div onClick={() => handleRoute("/ecommerce")}>
              <div className="bg-white flex flex-col items-center justify-center pt-8 pb-4 shadow-sm rounded-xl mx-2 gap-y-2">
                <img
                  src={
                    "https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQp7_6BOtmnzK4Qz1mf1JYNEhkgKPzymcgCRNUli0AqgFzgLlqJJyLQyrnRfLlJVeAckX4&usqp=CAU"
                  }
                  alt=""
                  className="rounded-full w-14 h-14 object-fill"
                />
                <h3 className="font-semibold capitalize">POS</h3>
              </div>
            </div>
            <div onClick={() => handleRoute("/qr-code-order")}>
              <div className="bg-white flex flex-col items-center justify-center pt-8 pb-4 shadow-sm rounded-xl mx-2 gap-y-2">
                <img
                  src={
                    "https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQp7_6BOtmnzK4Qz1mf1JYNEhkgKPzymcgCRNUli0AqgFzgLlqJJyLQyrnRfLlJVeAckX4&usqp=CAU"
                  }
                  alt=""
                  className="rounded-full w-14 h-14 object-fill"
                />
                <h3 className="font-semibold capitalize">QrCode</h3>
              </div>
            </div>
            <div onClick={() => handleRoute("/website-builder")}>
              <div className="bg-white flex flex-col items-center justify-center pt-8 pb-4 shadow-sm rounded-xl mx-2 gap-y-2">
                <img
                  src={
                    "https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQp7_6BOtmnzK4Qz1mf1JYNEhkgKPzymcgCRNUli0AqgFzgLlqJJyLQyrnRfLlJVeAckX4&usqp=CAU"
                  }
                  alt=""
                  className="rounded-full w-14 h-14 object-fill"
                />
                <h3 className="font-semibold capitalize">Website Builder</h3>
              </div>
            </div>
            <div onClick={() => handleRoute("/dynamic-form")}>
              <div className="bg-white flex flex-col items-center justify-center pt-8 pb-4 shadow-sm rounded-xl mx-2 gap-y-2">
                <img
                  src={
                    "https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQp7_6BOtmnzK4Qz1mf1JYNEhkgKPzymcgCRNUli0AqgFzgLlqJJyLQyrnRfLlJVeAckX4&usqp=CAU"
                  }
                  alt=""
                  className="rounded-full w-14 h-14 object-fill"
                />
                <h3 className="font-semibold capitalize">Dynamic Form</h3>
              </div>
            </div>
            <div onClick={() => handleRoute("/blog")}>
              <div className="bg-white flex flex-col items-center justify-center pt-8 pb-4 shadow-sm rounded-xl mx-2 gap-y-2">
                <img
                  src={
                    "https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQp7_6BOtmnzK4Qz1mf1JYNEhkgKPzymcgCRNUli0AqgFzgLlqJJyLQyrnRfLlJVeAckX4&usqp=CAU"
                  }
                  alt=""
                  className="rounded-full w-14 h-14 object-fill"
                />
                <h3 className="font-semibold capitalize">Blog</h3>
              </div>
            </div>
            <div onClick={() => handleRoute("/dib-attendance")}>
              <div className="bg-white flex flex-col items-center justify-center pt-8 pb-4 shadow-sm rounded-xl mx-2 gap-y-2">
                <img
                  src={
                    "https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQp7_6BOtmnzK4Qz1mf1JYNEhkgKPzymcgCRNUli0AqgFzgLlqJJyLQyrnRfLlJVeAckX4&usqp=CAU"
                  }
                  alt=""
                  className="rounded-full w-14 h-14 object-fill"
                />
                <h3 className="font-semibold capitalize">Dib Attendance</h3>
              </div>
            </div>
            <div onClick={() => handleRoute("/online-order")}>
              <div className="bg-white flex flex-col items-center justify-center pt-8 pb-4 shadow-sm rounded-xl mx-2 gap-y-2">
                <img
                  src={
                    "https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQp7_6BOtmnzK4Qz1mf1JYNEhkgKPzymcgCRNUli0AqgFzgLlqJJyLQyrnRfLlJVeAckX4&usqp=CAU"
                  }
                  alt=""
                  className="rounded-full w-14 h-14 object-fill"
                />
                <h3 className="font-semibold capitalize">Online Delivery</h3>
              </div>
            </div>
          </div>
        </Menu.Items>
      </Transition>
    </Menu>
  )
}

function EditInactiveIcon(props) {
  return (
    <svg
      {...props}
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M4 13V16H7L16 7L13 4L4 13Z"
        fill="#EDE9FE"
        stroke="#A78BFA"
        strokeWidth="2"
      />
    </svg>
  )
}

function EditActiveIcon(props) {
  return (
    <svg
      {...props}
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M4 13V16H7L16 7L13 4L4 13Z"
        fill="#8B5CF6"
        stroke="#C4B5FD"
        strokeWidth="2"
      />
    </svg>
  )
}

function DuplicateInactiveIcon(props) {
  return (
    <svg
      {...props}
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M4 4H12V12H4V4Z"
        fill="#EDE9FE"
        stroke="#A78BFA"
        strokeWidth="2"
      />
      <path
        d="M8 8H16V16H8V8Z"
        fill="#EDE9FE"
        stroke="#A78BFA"
        strokeWidth="2"
      />
    </svg>
  )
}

function DuplicateActiveIcon(props) {
  return (
    <svg
      {...props}
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M4 4H12V12H4V4Z"
        fill="#8B5CF6"
        stroke="#C4B5FD"
        strokeWidth="2"
      />
      <path
        d="M8 8H16V16H8V8Z"
        fill="#8B5CF6"
        stroke="#C4B5FD"
        strokeWidth="2"
      />
    </svg>
  )
}

function ArchiveInactiveIcon(props) {
  return (
    <svg
      {...props}
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <rect
        x="5"
        y="8"
        width="10"
        height="8"
        fill="#EDE9FE"
        stroke="#A78BFA"
        strokeWidth="2"
      />
      <rect
        x="4"
        y="4"
        width="12"
        height="4"
        fill="#EDE9FE"
        stroke="#A78BFA"
        strokeWidth="2"
      />
      <path d="M8 12H12" stroke="#A78BFA" strokeWidth="2" />
    </svg>
  )
}

function ArchiveActiveIcon(props) {
  return (
    <svg
      {...props}
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <rect
        x="5"
        y="8"
        width="10"
        height="8"
        fill="#8B5CF6"
        stroke="#C4B5FD"
        strokeWidth="2"
      />
      <rect
        x="4"
        y="4"
        width="12"
        height="4"
        fill="#8B5CF6"
        stroke="#C4B5FD"
        strokeWidth="2"
      />
      <path d="M8 12H12" stroke="#A78BFA" strokeWidth="2" />
    </svg>
  )
}

function MoveInactiveIcon(props) {
  return (
    <svg
      {...props}
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path d="M10 4H16V10" stroke="#A78BFA" strokeWidth="2" />
      <path d="M16 4L8 12" stroke="#A78BFA" strokeWidth="2" />
      <path d="M8 6H4V16H14V12" stroke="#A78BFA" strokeWidth="2" />
    </svg>
  )
}

function MoveActiveIcon(props) {
  return (
    <svg
      {...props}
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path d="M10 4H16V10" stroke="#C4B5FD" strokeWidth="2" />
      <path d="M16 4L8 12" stroke="#C4B5FD" strokeWidth="2" />
      <path d="M8 6H4V16H14V12" stroke="#C4B5FD" strokeWidth="2" />
    </svg>
  )
}

function DeleteInactiveIcon(props) {
  return (
    <svg
      {...props}
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <rect
        x="5"
        y="6"
        width="10"
        height="10"
        fill="#EDE9FE"
        stroke="#A78BFA"
        strokeWidth="2"
      />
      <path d="M3 6H17" stroke="#A78BFA" strokeWidth="2" />
      <path d="M8 6V4H12V6" stroke="#A78BFA" strokeWidth="2" />
    </svg>
  )
}

function DeleteActiveIcon(props) {
  return (
    <svg
      {...props}
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <rect
        x="5"
        y="6"
        width="10"
        height="10"
        fill="#8B5CF6"
        stroke="#C4B5FD"
        strokeWidth="2"
      />
      <path d="M3 6H17" stroke="#C4B5FD" strokeWidth="2" />
      <path d="M8 6V4H12V6" stroke="#C4B5FD" strokeWidth="2" />
    </svg>
  )
}
