import React from 'react'
import DibTechLogo from "../../assets/icons/dibtechLogo.svg";

const DibTechMainLogo = () => {
  return (
    <div>
      <a href="/" className='w-full flex items-center'>
        <img
          className="h-8 w-auto"
          src={DibTechLogo}
          alt="DibTech Logo"
        /><span className='font-medium pl-1'>ibtech Clients</span></a>
    </div>
  )
}

export default DibTechMainLogo