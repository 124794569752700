import React, { Fragment, useEffect, useState } from "react";
import { Dialog, Menu, Transition } from "@headlessui/react";
import UserIcon from "../../assets/icons/userIcon.svg";
import { apiInstance } from "../../helpers/axiosInstance";
import { classNames } from "../../utils/classNames";
import { removeLocalKey } from "../../helpers/sessionKey";

const SideBar = (props: any) => {
  const {
    children,
    navigationComponent,
    setSidebarOpen,
    check,
    toggleSidebar,
  } = props;
  const [userData, setUserData] = useState<any>([]);

  const handleLogout = () => {
    removeLocalKey("token");
    window.location.replace(window.location.origin);
  };

  useEffect(() => {
    fetchUserData();
  }, []);

  const fetchUserData = async () => {
    try {
      const response = await apiInstance.get("user/me/");
      if (response && response.data) {
        setUserData(response.data);
      }
    } catch (error) {
      console.log("Error fetching user data:", error);
    }
  };

  return (
    <div>
      <Menu
        as="div"
        className="ml-3 relative shadow-md m-[20px] p-[10px] rounded-lg bg-white"
      >
        {({ open }) => (
          <>
            <div>
              <Menu.Button className="max-w-xs flex gap-2 items-center text-sm rounded-full focus:outline-none">
                <span className="sr-only">Open user menu</span>
                <img
                  className="h-12 w-12 rounded-full"
                  src={UserIcon}
                  alt="User Icons"
                />
                <span className={"text-left"}>
                  {userData?.full_name && (
                    <>
                      {userData.full_name.charAt(0).toUpperCase() +
                        userData.full_name.slice(1)}{" "}
                      <br />
                      <span className={"text-left text-xs text-gray-400"}>
                        {userData?.is_superuser ? "Super User" : "User"}
                      </span>
                    </>
                  )}
                  <br />
                </span>
              </Menu.Button>
            </div>
            <Transition
              show={open}
              as={Fragment}
              enter="transition ease-out duration-100"
              enterFrom="transform opacity-0 scale-95"
              enterTo="transform opacity-100 scale-100"
              leave="transition ease-in duration-75"
              leaveFrom="transform opacity-100 scale-100"
              leaveTo="transform opacity-0 scale-95"
            >
              <Menu.Items
                static
                className="origin-top-right absolute right-0 mt-2 w-48 rounded-md shadow-lg py-1 bg-white ring-1 ring-black ring-opacity-5 focus:outline-none"
              >
                <Menu.Item>
                  {({ active }) => (
                    <a
                      href={"/profile"}
                      className={classNames(
                        active ? "bg-gray-100" : "",
                        "block px-4 py-1 text-sm text-gray-700"
                      )}
                    >
                      {"Profile"}
                    </a>
                  )}
                </Menu.Item>
                <Menu.Item>
                  {({ active }) => (
                    <a
                      href={"/change-password"}
                      className={classNames(
                        active ? "bg-gray-100" : "",
                        "block px-4 py-1 text-sm text-gray-700"
                      )}
                    >
                      Change Password
                    </a>
                  )}
                </Menu.Item>
                <Menu.Item>
                  {({ active }) => (
                    <p
                      onClick={handleLogout}
                      className={classNames(
                        active ? "bg-gray-100" : "",
                        "block px-4 py-1 text-sm text-gray-700"
                      )}
                    >
                      Sign Out
                    </p>
                  )}
                </Menu.Item>
              </Menu.Items>
            </Transition>
          </>
        )}
      </Menu>
      <div
        className="mt-5 flex-1 flex flex-col text-sm"
        onClick={toggleSidebar}
      >
        {navigationComponent}
      </div>
    </div>
  );
};

export default SideBar;
