import { Fragment, useEffect, useState } from 'react';
import { Dialog, Menu, Transition } from '@headlessui/react';
import UserIcon from "./assets/icons/userIcon.svg";
import DibTechLogo from "./assets/icons/dibtechLogo.svg";
import { removeLocalKey } from './helpers/sessionKey';
import { classNames } from './utils/classNames';
import isElectron from "./utils/checkElectron";
import MinimizeIcon from "./assets/minimize.svg";
import MaximizeIcon from "./assets/maximize.svg";
import CloseIcon from "./assets/close.svg";
import DIcon from "./assets/d.svg";
import ForwardButton from "./assets/toolbars/forward.svg";
import BackwardButton from "./assets/toolbars/back.svg";
import AvailableService from './Reusable/AvailableService';
import { apiInstance } from './helpers/axiosInstance';
import CrossIcon from './assets/icons/cross.svg'
import DibTechMainLogo from './Reusable/DibTechLogo';
import SideBar from './Reusable/Sidebar';

const DashboardLayout = (props: any) => {
  const { children, navigationComponent } = props;
  const [sidebarOpen, setSidebarOpen] = useState(false);
  const [userData, setUserData] = useState<any>([]);

  const handleSwipeRight = () => {
    let initialX = null;

    const onTouchStart = (e) => {
      initialX = e.touches[0].clientX;
    };

    const onTouchMove = (e) => {
      if (initialX === null) return;

      const currentX = e.touches[0].clientX;
      const diffX = currentX - initialX;

      const touchSensitivity = 50; // Adjust this value based on your preferred touch sensitivity.

      if (diffX > touchSensitivity && window.innerWidth < 768) {
        // Swiped right
        setSidebarOpen(true);
      }
    };

    const onTouchEnd = () => {
      initialX = null;
    };

    document.addEventListener('touchstart', onTouchStart);
    document.addEventListener('touchmove', onTouchMove);
    document.addEventListener('touchend', onTouchEnd);

    return () => {
      document.removeEventListener('touchstart', onTouchStart);
      document.removeEventListener('touchmove', onTouchMove);
      document.removeEventListener('touchend', onTouchEnd);
    };
  };

  useEffect(() => {
    document.addEventListener('touchstart', handleSwipeRight);

    return () => {
      document.removeEventListener('touchstart', handleSwipeRight);
      // handleSwipeRight();
    };
  }, []);

  const handleCloseElectron = () => {
    removeLocalKey("token");
    const { ipcRenderer } = window.require("electron");
    return ipcRenderer.send("closeApp");
  };

  // ... (existing code for handleMinimizeElectron, handleMaximizeElectron, and other functions)
  const handleMinimizeElectron = () => {
    const { ipcRenderer } = window.require("electron");
    return ipcRenderer.send("minimizeApp");
  }
  const handleMaximizeElectron = () => {
    const { ipcRenderer } = window.require("electron");
    return ipcRenderer.send("maximizeApp");
  }

  const handleLogout = () => {
    removeLocalKey("token");
    window.location.replace(window.location.origin);
  };

  useEffect(() => {
    fetchUserData();
  }, []);

  const fetchUserData = async () => {
    try {
      const response = await apiInstance.get('user/me/');

      if (response && response.data) {
        setUserData(response.data);
      }
    } catch (error) {
      console.log('Error fetching user data:', error);
    }
  };

  const [screenWidth, setScreenWidth] = useState(window.innerWidth);

  // Function to handle window resize
  const handleWindowResize = () => {
    setScreenWidth(window.innerWidth);
    // Close the sidebar on large devices
    if (window.innerWidth >= 768) {
      setSidebarOpen(false);
    }
  };

  useEffect(() => {
    // Attach an event listener to handle window resize
    window.addEventListener('resize', handleWindowResize);

    return () => {
      // Remove the event listener when the component unmounts
      window.removeEventListener('resize', handleWindowResize);
    };
  }, []);

  const toggleSidebar = () => {
    setSidebarOpen(false);
  };

  return (
    <div>
      <div style={{ height: "100vh" }}>
        {isElectron() ? (
          <div className="fixed h-12 bg-[#434d7e] flex justify-between items-center w-full top-0 left-0 mb-4 z-50">
            {/* ... (existing code for Electron top bar) */}
            <div className="ml-2 flex gap-4">
              <button className={"top-2 left-2"}>
                <img className="w-6 h-6 m-0 p-1 hover:bg-indigo-800" src={DIcon} alt="" />
              </button>
              <button className={" cursor-pointer"} onClick={() => window.history.go(-1)}>
                <img className="w-6 h-6 m-0 p-1 hover:bg-indigo-800 cursor-pointer" src={BackwardButton} alt="" />
              </button>
              <button className={" cursor-pointer"} onClick={() => window.history.go(+1)}>
                <img className="w-6 h-6 m-0 p-1 hover:bg-indigo-800 cursor-pointer" src={ForwardButton} alt="" />
              </button>
            </div>
            <div className="text-white text-lg w-full text-center" id="application-titlebar">DIB POS</div>
            <div className="flex gap-4 mr-2">
              <div><AvailableService /></div>
              <button onClick={() => handleMinimizeElectron()}>
                <img className="w-6 h-6 m-1 p-1 hover:bg-indigo-800" src={MinimizeIcon} alt="" />
              </button>
              <button onClick={() => handleMaximizeElectron()}>
                <img className="w-6 h-6 m-1 p-1 hover:bg-indigo-800" src={MaximizeIcon} alt="" />
              </button>
              <button onClick={() => handleCloseElectron()}>
                <img className="w-6 h-6 m-1 p-1 hover:bg-red-600" src={CloseIcon} alt="" />
              </button></div>
          </div>
        ) : null}

        <div className='h-full flex overflow-hidden bg-gray-100'>
          <Transition.Root show={sidebarOpen} as={Fragment}>
            <Dialog
              as="div"
              static
              className="fixed inset-0 flex z-40"
              open={sidebarOpen}
              onClose={setSidebarOpen}
            >
              {/* ... (existing code for sidebar dialog) */}
              <Transition.Child
                as={Fragment}
                enter="transition-opacity ease-linear duration-300"
                enterFrom="opacity-0"
                enterTo="opacity-100"
                leave="transition-opacity ease-linear duration-300"
                leaveFrom="opacity-100"
                leaveTo="opacity-0"
              >
                <Dialog.Overlay className="fixed inset-0 bg-gray-600 bg-opacity-75" />
              </Transition.Child>
              <Transition.Child
                as={Fragment}
                enter="transition ease-in-out duration-300 transform"
                enterFrom="-translate-x-full"
                enterTo="translate-x-0"
                leave="transition ease-in-out duration-300 transform"
                leaveFrom="translate-x-0"
                leaveTo="-translate-x-full"
              >
                <div className="relative flex-1 flex flex-col max-w-xs w-full pt-5 pb-4 bg-[#F4F5FA]">
                  <Transition.Child
                    as={Fragment}
                    enter="ease-in-out duration-300"
                    enterFrom="opacity-0"
                    enterTo="opacity-100"
                    leave="ease-in-out duration-300"
                    leaveFrom="opacity-100"
                    leaveTo="opacity-0"
                  >
                    <div className="absolute top-0 right-0 -mr-9 pt-1">
                      <button
                        type="button"
                        className="flex items-center justify-center h-8 w-8 rounded-full focus:outline-none focus:ring-2 focus:ring-inset focus:ring-[#ffffff]"
                        onClick={() => setSidebarOpen(false)}
                      >
                        {/* <span className="sr-only">Close sidebar</span> */}
                        <img src={CrossIcon} alt="" />
                      </button>
                    </div>
                  </Transition.Child>
                  <div className="flex-shrink-0 flex items-center px-4">
                    <DibTechMainLogo />
                  </div>
                  <SideBar toggleSidebar={toggleSidebar} navigationComponent={navigationComponent} />
                </div>
              </Transition.Child>
              {/* <div className="flex-shrink-0 w-14" aria-hidden="true">
              {/* Dummy element to force sidebar to shrink to fit close icon 
            </div> */}
            </Dialog>
          </Transition.Root>

          <div className="hidden bg-[#F4F5FA] md:flex md:flex-shrink-0 p-2 shadow rounded-r-2xl">
            {/* ... (existing code for static sidebar) */}
            <div className="flex flex-col w-64">
              {/* Sidebar component, swap this element with another sidebar if you like */}
              <div className="flex flex-col flex-grow pt-5 pb-4 overflow-y-auto">
                {isElectron() ? "" :
                  <div className="flex items-center flex-shrink-0 px-4">
                    <DibTechMainLogo />
                  </div>

                }
                {/* Profile dropdown */}
                <SideBar toggleSidebar={null} navigationComponent={navigationComponent} />
              </div>
            </div>
          </div>

          <div className="flex flex-col w-0 flex-1 overflow-hidden">
            <main className="flex-1 relative overflow-y-auto focus:outline-none">
              <div className="py-6">
                <div className="max-w-7xl flex justify-between mx-auto px-4 sm:px-6 md:px-8">
                  <div>
                  </div>
                </div>
                <div className="max-w-7xl mx-auto px-4 sm:px-6 md:px-8">
                  <div className={isElectron() ? "py-12" : "py-4"}>
                    {children}
                  </div>
                </div>
              </div>
            </main>
          </div>
        </div>
      </div >
    </div>
  );
};

export default DashboardLayout;
