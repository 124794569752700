function isElectron(): boolean {
    // Renderer process
    if (typeof window !== 'undefined' && typeof window.process === 'object') {
      return true;
    }
  
    // Main process
    if (typeof process !== 'undefined' && typeof process.versions === 'object' && !!process.versions.electron) {
      return true;
    }
  
    // Detect the user agent when the `nodeIntegration` option is set to false
    if (typeof navigator === 'object' && navigator.userAgent.indexOf('Electron') >= 0) {
      return true;
    }
  
    return false;
  }
  
  export default isElectron;